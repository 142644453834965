import { Button, Input, Select } from 'antd'
import '../assets/style/index.scss'
import RemarkComponent from '../component/RemarkComponent'
import IndexFooter from '../component/IndexFooter'
import { getPropertyInfo, getPropertyList } from '../request/api'
import { useEffect, useState } from 'react'
import ListDataDity from '../utils/ListDataDity'
import { useNavigate, Link } from 'react-router-dom'
import { useViewport } from "../hooks/useViewport"

export default function Index () {
    const navigateTo = useNavigate()
    const { width } = useViewport()

    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))
    let [propertyList,setPropertyList] = useState([])

    const propertyCol = [
        {
            value: "大阪  シェアプレイス駒沢 ",
            label: "大阪  シェアプレイス駒沢 "
        }
    ]

    const roomCol = [
        {
            value: "42",
            label: "42室"
        }
    ]

    const getData = async () => {
        await getPropertyList()
        .then((res) => {
            let arr = ListDataDity({
                data: res.data.list,
                type: "index"
            })
            setPropertyList([...arr])
        }).catch((err) => {
            
        });
    }

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`${anchorName}`)[0];
        anchorElement.scrollIntoView({behavior: 'smooth', block:'start'})
    }

    const scrollToIndexAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`${anchorName}`)[0];
        anchorElement.scrollIntoView({ behavior: 'smooth', block:'start' })
    }

    const navigateHandle = (e) => {
        window.scrollTo(0, 0);
        navigateTo(`/Detail?${e.id}`)
    }

    useEffect(()=>{
        getData()
    },[])

    // useEffect(() => {
    //     // 在组件加载完成后，将页面滚动至顶部
    //     // window.scrollTo(0, 0);
    //     window.onbeforeunload = function(){
    //         //刷新后页面自动回到顶部
    //         console.log(1);
    //         document.documentElement.scrollTop = 0;  //ie下
    //         document.body.scrollTop = 0;
    //     }
    //   }, []);


    useEffect(()=>{
        const handleScroll = () => {
            if (window.scrollY > 1000) {
                document.getElementsByClassName("Index-beaver")[0].classList.add("show-beaver")
            }else {
                let beaverEle = document.getElementsByClassName("show-beaver")[0];
                if (beaverEle) {
                    beaverEle.classList.remove("show-beaver")
                }
            }
        }
        window.addEventListener('scroll',handleScroll);
        return () => {
            window.removeEventListener('scroll',handleScroll)
        } 
    },[])

    useEffect(()=>{
        let point = sessionStorage.getItem('anchorPoint');
        if (point && width > 480) {
            setTimeout(()=>{
                scrollToIndexAnchor(point)
            },200)
        }
    },[anchor])

    useEffect(()=>{
        window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
        return window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
    },[])


    return <div className={`Index ${width < 481 && "IndexMobile"}`}>
        <div className='Index-content'>
        <div className='Index-banner'>
            <img src={require('../assets/images/img/index-banner.png')} alt=''/>
            <div className='banner-title'>
                <span className='banner-title-animation'>さ</span>
                <span className='banner-title-animation'>あ</span>
                <span className='symbol banner-title-animation'>!</span>
                <span className='banner-title-animation'>自</span>
                <span className='banner-title-animation' >分</span>
                <span className='banner-title-animation'>の</span>
                <span className='banner-title-animation'>部</span>
                <span className='banner-title-animation'>屋</span>
                <span className='banner-title-animation'>を</span>
                <span className='banner-title-animation'>見</span>
                <span className='banner-title-animation'>つ</span>
                <span className='banner-title-animation'>け</span>
                <span className='banner-title-animation'>よ</span>
                <span className='banner-title-animation'>う</span>
            </div>
            <div className='banner-subtitle'>
                ハッピーです
            </div>
            <div className='banner-line'></div>
            <div className='banner-beaver'>
                <div className='left-box'>
                    <div className='animation-beaver-left'>

                    </div>
                </div>
            </div>
        </div>
        <div className='Index-border'>
            <img src={require('../assets/images/img/index-border.png')} alt=''/>
        </div>
        <div className='Index-beaver' onClick={()=>scrollToAnchor("remark-bottom")}>
            <div className='img-box'>
                <img className='img-bottom' src={require('../assets/images/icon/icon-beaver.png')}  alt=''/>
                <img className='img-top' src={require('../assets/images/icon/icon-beaver-text.png')} alt='' />
            </div>
        </div>
        {
            width > 480 && <div className='Index-thing Index-thing-anchor'>
            <div className='thing-top'>
                <p className='title'>賃貸物件</p>
                <p className='desc'>お薦め</p>
                <div className='line'></div>
            </div>
            {
                propertyList.map((e,i)=>(
                    (i+1) % 2 === 1 ? i === 0 ? <div className='thing-bottom first-thing' key={i}>
                        <div className='flex-box'>
                            <div className={`link-mask ${e.status === 2 ? 'showMask' : ''}`}></div>
                            <a className='thing-content' href={`/Detail?${e.id}`}>
                                <p className='content-title'>
                                    <img src={require('../assets/images/icon/title-bg1.png')} alt=''/>
                                    {/* <span onClick={()=>navigateHandle(e)}>{e.name}</span> */}
                                    <span className='li-title' >{e.name}</span>
                                </p>
                                <div className='content-li'>
                                    <p className='content-subtitle'>こだわり</p>
                                    <div className='desc tag'>
                                        {
                                            e.advantages?.map((e,i)=>(
                                                <p key={i}>{e}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>交通</p>
                                    <div className='desc'>
                                        <p>{e.navigation}</p>
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>住所</p>
                                    <div className='desc'>
                                        <p>{e.location}</p>
                                    </div>
                                </div>
                            </a>
                            <div className='thing-img ' >
                                <a href={`/Detail?${e.id}`}>
                                    <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt=''/>
                                </a>
                                {
                                    e.status == 2 ? <div className='mask'>
                                        <p>改装中</p>
                                    </div>
                                    : 
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className='thing-bottom third-thing' key={i}>
                        <div className='topBg'></div>
                        <div className='flex-box'>
                            <div className={`link-mask ${e.status === 2 ? 'showMask' : ''}`}></div>
                            <a className='thing-content' href={`/Detail?${e.id}`}>
                                <p className='content-title'>
                                    <img src={require('../assets/images/icon/title-bg1.png')} alt=''/>
                                    {/* <span onClick={()=>navigateTo(`/Detail?${e.id}`)}>{e.name}</span> */}
                                    <span className='li-title' href={`/Detail?${e.id}`}>{e.name}</span>

                                </p>
                                <div className='content-li'>
                                    <p className='content-subtitle'>こだわり</p>
                                    <div className='desc tag'>
                                        {
                                            e.advantages?.map((e,i)=>(
                                                <p key={i}>{e}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>交通</p>
                                    <div className='desc'>
                                        <p>{e.navigation}</p>
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>住所</p>
                                    <div className='desc'>
                                        <p>{e.location}</p>
                                    </div>
                                </div>
                            </a>
                            <div className='thing-img '>
                                <a href={`/Detail?${e.id}`}>
                                    <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt=''/>
                                </a>
                                {
                                    e.status == 2 ? <div className='mask'>
                                        <p>改装中</p>
                                    </div>
                                    : 
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className='thing-bottom second-thing' key={i}>
                        <div className='topBg'></div>
                        <div className='flex-box'>
                            <div className={`link-mask ${e.status === 2 ? 'showMask' : ''}`}></div>
                            <div className='thing-img '>
                                <a href={`/Detail?${e.id}`}>
                                    <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt='' />
                                </a>
                                {
                                    e.status == 2 ? <div className='mask'>
                                        <p>改装中</p>
                                    </div>
                                    : 
                                    ''
                                }
                            </div>
                            <a className='thing-content' href={`/Detail?${e.id}`}>
                                <p className='content-title'>
                                    <img src={require('../assets/images/icon/title-bg2.png')} alt=''/>
                                    {/* <span onClick={()=>navigateTo(`/Detail?${e.id}`)}>{e.name}</span> */}
                                    <span className='li-title' href={`/Detail?${e.id}`}>{e.name}</span>
                                    
                                </p>
                                <div className='content-li'>
                                    <p className='content-subtitle'>こだわり</p>
                                    <div className='desc tag'>
                                        {
                                            e.advantages?.map((e,i)=>(
                                                <p key={i}>{e}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>交通</p>
                                    <div className='desc'>
                                        <p>{e.navigation}</p>
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>住所</p>
                                    <div className='desc'>
                                        <p>{e.location}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='thing-bg'>
                            <img src={require("../assets/images/img/second-thing-bg.png")} alt='' />
                        </div>
                    </div>
                ))
            }
            </div>
        }
        {
            width < 481 && <div className='Index-thing'>
            <div className='thing-top'>
                <p className='title'>賃貸物件</p>
                <p className='desc'>お薦め</p>
                <div className='line'></div>
            </div>
            {
                propertyList.map((e,i)=>(
                    <div className='thing-bottom first-thing' key={i}>
                        <div className='flex-box'>
                            <div className={`link-mask ${e.status === 2 ? 'showMask' : ''}`}></div>
                            <a className='thing-content' href={`/Detail?${e.id}`}>
                                <p className='content-title'>
                                    <img src={require('../assets/images/icon/title-bg1.png')} alt=''/>
                                    <span className='li-title' href={`/Detail?${e.id}`}>{e.name}</span>
                                </p>
                                <div className='content-li'>
                                    <p className='content-subtitle'>こだわり</p>
                                    <div className='desc tag'>
                                        {
                                            e.advantages?.map((e,i)=>(
                                                <p key={i}>{e}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>交通</p>
                                    <div className='desc'>
                                        <p>{e.navigation}</p>
                                    </div>
                                </div>
                                <div className='content-li'>
                                    <p className='content-subtitle'>住所</p>
                                    <div className='desc'>
                                        <p>{e.location}</p>
                                    </div>
                                </div>
                            </a>
                            <div className='thing-img '>
                                <a href={`/Detail?${e.id}`}>
                                    <img src={`${process.env.REACT_APP_API_URL}/${e.image}`} alt=''/>
                                </a>
                                {
                                    e.status == 2 ? <div className='mask'>
                                        <p>改装中</p>
                                    </div>
                                    : 
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                    
                ))
            }
            </div>
        }
        <div className='Index-remark'>
            <div className='remark-top'>
                {
                    [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26].map((e,i)=>{
                        return <div className='bg-li' key={i}>
                            
                        </div>
                    })
                }
            </div>
            <div className='remark-bottom'>
                <div className='remark-title'>
                    <p className='title'>お問い合わせ</p>
                    <p className='subtitle'>なんでも聞いてね</p>
                    <div className='line'></div>
                </div>
                <div className='remark-img'>
                    <img src={require('../assets/images/img/remark-banner.png')} alt=''/>
                </div>
                <div className='remark-wave'>
                    <img src={require('../assets/images/img/wave-bg.png')} alt=''/>
                </div>
                <div className='remark-content'>
                    <RemarkComponent propertyList={propertyList} type="index" />
                </div>
            </div>
        </div>
        </div>
        <div className='Index-footer'>
            <IndexFooter />
        </div>
    </div>
}


